require("insert-css")(".active.modal{display:block;text-align:center;padding:50px;width:33%;margin:70px 33%}.actions{text-align:center!important}.timer{color:#999!important}.timer-element{position:fixed;bottom:0;right:10px}.fillscreen{position:fixed;top:0;left:0;right:0;bottom:0;background-color:rgba(0,0,0,.85)}");
var __vue_template__ = "<div class=\"timer-element\">\n    <div class=\"timer\">\n      <span v-if=\"timeBeforeSessionExpire\"><i class=\"icon info\"></i> Sesja wygasa za {{ timeBeforeSessionExpire }} {{ formatMinutes(timeBeforeSessionExpire) }}.\n      </span>\n    </div>\n    <div class=\"fillscreen\" v-if=\"!!delta &amp;&amp; delta < 60\">\n      <div class=\"ui basic active modal\">\n        <div class=\"ui icon header\">\n          <i class=\"attention icon\"></i>\n          Uwaga!\n        </div>\n        <div class=\"content\">\n          <p>\n            Z powodu braku aktywności Twoja sesja wygasa za {{ timeBeforeSessionExpire }} {{ formatMinutes(timeBeforeSessionExpire) }}.\n            Chcesz ją przedłużyć?\n          </p>\n        </div>\n        <div class=\"actions\">\n          <div class=\"ui red basic cancel inverted button\" v-on=\"click: logout()\">\n            <i class=\"remove icon\"></i>\n            Nie, wyloguj\n          </div>\n          <div class=\"ui green ok inverted button\" v-on=\"click: refresh()\">\n            <i class=\"checkmark icon\"></i>\n            Tak, przedłuż\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>";
module.exports = {
  name: "sessionExpireTimer",
  data() {
    return {
      sessionMaxAge: null,
      sessionExpiredAt: null,
      timeBeforeSessionExpire: 0,
      delta: Infinity
    };
  },
  methods: {
    recountTime() {
      this.sessionMaxAge = parseInt(localStorage.getItem("SessionMaxAge"), 10) || Infinity;
      this.sessionExpiredAt = Date.now() + this.sessionMaxAge;
      this.updateTimeBeforeSessionExpire();
    },
    logout() {
      this.request({ endPoint: ENDPOINTS.auth.logOut, method: "post" }).done(() => (window.location = "/login"));
    },
    refresh() {
      return this.request({ endPoint: ENDPOINTS.auth.userinfo }).success(() => this.recountTime());
    },
    updateTimeBeforeSessionExpire() {
      if (this.sessionExpiredAt) {
        this.delta = Math.abs(this.sessionExpiredAt - new Date(Date.now())) / 1000;
        this.timeBeforeSessionExpire = Math.floor(this.delta / 60) % 60 || 60;
      }
    },
    formatMinutes(timeInMinutes) {
      const time = parseInt(timeInMinutes, 10) || 60;
      if (time === 1) {
        return "minutę";
      }
      const lastDigit = parseInt(timeInMinutes, 10) % 10;
      return lastDigit > 1 && lastDigit < 5 ? "minuty" : "minut";
    }
  },
  ready() {
    this.$root.$on("requestMade", () => this.recountTime());
    this.recountTime();
    setInterval(this.updateTimeBeforeSessionExpire, 10000);
  },
  mixins: [require("../scripts/mixins/http")]
};
;(typeof module.exports === "function"? module.exports.options: module.exports).template = __vue_template__;
