const utils = {
  userData: require("./userData"),
  passwordRequirements: require("./passwordRequirements"),
  map: require("./map"),
  http: require("./http")
};
const defaultMixins = [utils.http];
/**
 * @param modules - modules you want to import in utils,
 * accepts a single argument or an array of arguments,
 * provide a string for simple module or an object for module returning a function
 * i.e.:
 * mixin: [ require(PATH/utils("http")) ]
 * OR
 * mixin: [ require(PATH/utils({ mixin: "passwordRequirements", params: ["param1", param2] })) ]
 * OR
 * mixin: [ require(PATH/utils([ { mixin: "passwordRequirements", params: ["param1", param2] }, "http" ])) ]
 */
module.exports = function (modules) {
  const mixins = [...defaultMixins];
  if (!modules) {
    return {
      mixins: mixins
    };
  }

  if (!_.isArray(modules)) {
    modules = [modules];
  }

  modules.forEach((e) => {
    if (typeof e === "object" && e !== null) {
      mixins.push(utils[e.mixin](...e.params));
    } else {
      mixins.push(utils[e]);
    }
  });

  return {
    mixins: mixins
  };
};
