require("insert-css")("");
var __vue_template__ = "<div class=\"ui segment\" style=\"min-height: 100vh\" id=\"master\">\n    <sidebar content-id=\"content\"></sidebar>\n    <toolbar user-info=\"{{@ userData }}\"></toolbar>\n    <div class=\"ui twelve column centered row\" id=\"content\">\n      <div class=\"ui center aligned text container\">\n        <h1 style=\"margin-top: 100px; !important; font-size: 96px\">\n          <i class=\"shipping icon\"></i>&nbsp;TMS\n        </h1>\n        <h2 style=\"margin-bottom: 45px\">\n          {{ $translatesGlobal.tms_translated }}\n        </h2>\n        <div class=\"ui center aligned text container\">\n          <button class=\"ui right labeled icon large green button\" id=\"toggleMenu\" v-if=\"!isPaymentBlockReason\">\n            <i class=\"left arrow icon\"></i> {{ $translatesGlobal.tms_front_page_opts }}\n          </button>\n          <div v-if=\"isPaymentBlockReason\" style=\"margin-top: 25px\">\n            {{{ getPaymentBlockReason() }}}\n          </div>\n          <div v-if=\"isGlobalNotification\" style=\"margin-top: 25px\">\n            {{{ getNotificationContent() }}}\n        </div>\n      </div>\n    </div>\n  </div>\n</div>";
module.exports = {
  el: "#app",
  data() {
    return {
      userData: null,
      GlobalNotification: { isActive: false, content: {} }
    };
  },
  ready() {
    this.getUserData();
    this.getGlobalNotification();
    if (!this.isPaymentBlockReason) {
      $("#toggleMenu").click(() => $(".ui.sidebar").sidebar("toggle"));
    }
  },
  computed: {
    isGlobalNotification() {
      return !!(this.GlobalNotification.isActive && this.GlobalNotification.content && this.GlobalNotification.content[this.$languageSuffixGlobal]);
    },
    isPaymentBlockReason() {
      return !!_.get(this, "userData.PaymentBlockReason", null);
    }
  },
  methods: {
    getGlobalNotification(){
      this.request({ endPoint: ENDPOINTS.common.getGlobalNotification }).done(data => this.GlobalNotification = data || { isActive: false, content: {} });
    },
    getNotificationContent() {
      return this.GlobalNotification.content[this.$languageSuffixGlobal];
    },
    getPaymentBlockReason() { 
      const reasonName = this.userData && this.userData.PaymentBlockReason
        ? this.$translatesGlobal[`payment_block_reason_${this.userData.PaymentBlockReason.toLowerCase()}`]
        : this.$translatesGlobal.payment_block_reason_unspecified;
      return `<h2 style="color: red; font-weight: normal">${this.$translatesGlobal.you_account_is_blocked_for_reason.replace("$REASON$", reasonName)}</h2>`;
    }
  },
  components: {
    sidebar: require("./sidebar.vue"),
    toolbar: require("./toolbar.vue")
  },
  events: {
    unauthorized() {
      console.log("Warn: you are not logged in.");
    }
  },
  mixins: [require("../scripts/mixins/index")(["http", "userData"])]
};
;(typeof module.exports === "function"? module.exports.options: module.exports).template = __vue_template__;
