require("insert-css")("body{margin:0!important}#message{margin:15px auto}#access-denied{margin-top:15px;position:absolute;left:50%;margin-left:-75px}.ui.sidebar.inverted.vertical.menu .item{color:#ccc!important}.subheader{color:#eee!important}");
var __vue_template__ = "<div class=\"ui sidebar inverted vertical menu\">\n    <div v-show=\"message\" id=\"message\" class=\"ui icon header inverted\">\n      <i class=\"user icon\"></i>\n      <div class=\"content\">\n        <a href=\"/login\">Zaloguj się</a>\n        <div class=\"sub header\">{{ message }}</div>\n      </div>\n    </div>\n    <div v-show=\"noAccess\" id=\"access-denied\" class=\"ui icon header inverted\">\n      <i class=\"user icon\"></i>\n      <div class=\"content\">\n        <div class=\"sub header\">Brak dostępu do sekcji.</div>\n      </div>\n    </div>\n    <template v-repeat=\"item in menuItems\">\n      <template v-if=\"item.items === undefined\">\n        <a href=\"/{{ item.url }}\" class=\"item\">{{ item.label }}</a>\n      </template>\n      <div v-if=\"item.items\" class=\"item\">\n        <span class=\"subheader\">{{ item.label }}</span>\n        <template v-repeat=\"subItem in item.items\">\n          <div class=\"menu\">\n            <a href=\"/{{ subItem.url }}\" class=\"item\">\n              <i class=\"{{ subItem.semanticIconClass }} icon\"></i>\n              {{ subItem.label }}\n            </a>\n          </div>\n        </template>\n      </div>\n    </template>\n  </div>";
module.exports = {
  data() {
    return {
      menuItems: {},
      message: ""
    };
  },
  props: ["contentId"],
  methods: {
    getItems() {
      this
        .request({ endPoint: ENDPOINTS.common.sitemap })
        .done(data => {
          if (!data.message && !data.noAccess) {
            this.menuItems = _.map(Object.keys(data), key => {
              const item = data[key];
              if (typeof item === "string") {
                return { label: key.toUpperCase(), url: item };
              }
              return {
                label: this.$translatesGlobal[item.translateSymbol] || key,
                items: _.map(item.items, i => ({
                  label: this.$translatesGlobal[i.translateSymbol] || i.label,
                  semanticIconClass: i.semanticIconClass,
                  url: i.url
                }))
              }
            });
          } else {
            if (data.message) {
              this.message = data.message;
            }
            if (data.noAccess) {
              this.$set("noAccess", data.noAccess);
            }
          }
        });
    }
  },
  mixins: [require("../scripts/mixins/http")],
  ready() {
    $(`#${this.contentId}`).addClass("pusher");
    $(".ui.sidebar").sidebar();
    this.getItems();
  }
};
;(typeof module.exports === "function"? module.exports.options: module.exports).template = __vue_template__;
