/**
 * Created by dzwigalowski on 2015-11-04.
 */
module.exports = {
  methods: {
    request(params, data, asJSON) {
      const self = this;
      const opts = {
        type: params.method || "GET",
        url:
          params.endPoint.indexOf("http://") === -1 && params.endPoint.indexOf("https://") === -1
            ? SITE_ROOT + params.endPoint
            : params.endPoint,
        data: data,
        error(xhr, txtStatus, error) {
          switch (xhr.status) {
            case 500:
              self.$emit("internalServerError", "Internal server error (500): " + xhr.responseText);
              break;
            case 404:
              self.$emit("notFound");
              break;
            case 401:
              self.$emit("unauthorized");
              break;
            case 403:
              self.$emit("forbidden");
              break;
          }
        }
      };
      if (asJSON) {
        opts.data = JSON.stringify(data);
        opts.dataType = "json";
        opts.contentType = "application/json; charset=UTF-8";
      }
      self.$root.$emit("requestMade");
      return $.ajax(opts);
    },
    checkSaveStatus(data, msg, isFixed) {
      const self = this;

      if (!_.isEmpty(data)) {
        if (data.constructor === Array) {
          _.forEach(data, function (item) {
            if (item[0].ID) {
              self.$emit("savedSuccessful", msg, isFixed);
            }
          });
        } else {
          if (data.ID) {
            self.$emit("savedSuccessful", msg, isFixed);
          }
        }
      }
    }
  },
  events: {
    savedSuccessful(msg, isFixed = false) {
      if (msg) {
        $.growl.notice({ title: "Sukces", message: msg, fixed: isFixed });
        return;
      }
      $.growl.notice({ title: "Sukces", message: "Zapisano pomyślnie", fixed: isFixed });
    },
    notFound() {
      $.growl.error({ message: "Strony nie znaleziono (404)", fixed: true });
    },
    unauthorized() {
      location.replace(SITE_ROOT + ENDPOINTS.user.login + "?backref=" + location.href);
    },
    internalServerError(statusText) {
      $.growl.error({ message: statusText || "Wewnętrzny błąd serwera (500)", fixed: true });
    },
    forbidden() {
      $.growl.error({ message: "Brak wymaganych uprawnień (403)", fixed: true });
    },
    defaultError(msg) {
      $.growl.error({ message: msg });
    }
  }
};
