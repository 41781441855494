/**
 * @param passwordField - field with password to be observed by this validator
 */
module.exports = function (passwordField) {
  return {
    data() {
      return {
        passwordRequirements: {
          minLength: 8,
          patternsToMatch: [
            {
              pattern: /\d/,
              displayName: "cyfrę"
            },
            {
              pattern: /[A-Z]/,
              displayName: "wielką literę"
            },
            {
              pattern: /[a-z]/,
              displayName: "małą literę"
            },
            {
              pattern: /[!@#$%^&*()?":{}|<>]/,
              displayName: "znak specjalny"
            }
          ]
        },
        correctPasswordFormat: false
      };
    },
    methods: {
      checkPassword(val) {
        const self = this;
        const patternChecks = [];
        self.passwordRequirements.patternsToMatch.forEach((e) => patternChecks.push(e.pattern.test(val)));
        return val.length >= self.passwordRequirements.minLength && patternChecks.every((check) => check === true);
      }
    },
    watch: {
      [passwordField](nv, ov) {
        const self = this;
        if (nv) {
          self.correctPasswordFormat = self.checkPassword(nv);
        }
      }
    }
  };
};
