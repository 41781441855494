require("insert-css")(".mini.ui.left.labeled.button .ui.basic.label,.mini.ui.left.labeled.button .ui.blue.button{padding-top:5px;padding-bottom:5px}.mini.ui.left.labeled.button .ui.blue.button{padding-left:10px;padding-right:10px}.not-logged-mbl{display:none}@media screen and (max-width:768px){.not-logged-mbl{display:inline}.not-logged{display:none}}");
var __vue_template__ = "<div class=\"mini ui left labeled button\">\n    <a class=\"user ui basic label\" v-show=\"ID\" v-on=\"click: gotoUserProfile()\">\n      <img class=\"ui avatar image\" v-show=\"ID\" v-attr=\"src: PhotoUrl\" alt=\"userPhoto\">&nbsp;\n      <span class=\"not-logged\">{{ FirstName }}</span>&nbsp;{{ LastName }}\n    </a>\n    <button v-show=\"ID\" v-on=\"click: logout()\" id=\"user-info-logout\" class=\"ui blue button\">\n      {{ !$languageSuffixGlobal ? 'Wyloguj' : $translatesGlobal.logout_button }}\n    </button>\n    <a class=\"ui basic label\" v-show=\"!ID\">\n      <img class=\"ui avatar image\" v-show=\"!ID\" src=\"/css/images/human.png\" alt=\"anonymousPhoto\">&nbsp;\n      <span class=\"not-logged\">Jesteś niezalogowany</span><span class=\"not-logged-mbl\">Niezalogowany</span>\n    </a>\n    <button v-show=\"!ID\" v-on=\"click: login()\" class=\"ui blue button\">Zaloguj</button>\n  </div>";
module.exports = {
  //el: '#app',
  data: function () {
    return {
      FirstName: "",
      LastName: "",
      PhotoUrl: "",
      ID: "",
      userInfo: null
    };
  },
  props: ["user", "userInfo"],
  mixins: [require("../scripts/mixins/http")],
  methods: {
    logout: function () {
      this
        .request({ endPoint: ENDPOINTS.auth.logOut, method: "post" })
        .done(() => {
          this.$data = {};
          location.replace(SITE_ROOT);
        });
    },
    login: function () {
      location.replace(`${SITE_ROOT}${ENDPOINTS.users.login}`);
    },
    gotoUserProfile: function () {
      location.replace(`${SITE_ROOT}${ENDPOINTS.users.edit}?User=${this.ID}`);
    }
  },
  ready: function () {
    this
      .request({ endPoint: ENDPOINTS.auth.userinfo })
      .done(data => {
        for (const key in this.$data) {
          if (key === "PhotoUrl" && !data[key] && this.FirstName && this.LastName) {
            this.$data[key] = ENDPOINTS.static.defaults.userPhoto;
            continue;
          } else if (key === "user") {
            continue;
          }
          this.$data[key] = data[key];
        }
        if (_.isObject(this.user)) {
          this.$set("user", data);
        }
        this.userInfo = data;
      });
  },
  events: {
    unauthorized: function () {
      this.$dispatch("unauthorized");
    }
  }
};
;(typeof module.exports === "function"? module.exports.options: module.exports).template = __vue_template__;
