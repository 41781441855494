const globalData = new Vue({
  data: {
    $languageSuffixGlobal: "PL",
    $translates: {
      acc_freight: "",
      acc_freight_in_routes: "",
      account_blocking: "",
      account_expiration_date: "",
      account_no_bic: "",
      account_no_iban: "",
      account_no_nrb: "",
      account_prod: "",
      account_test: "",
      accounting_info: "",
      accrued_date: "",
      ack_the_route: "",
      act_delivery_time: "",
      action: "",
      activate: "",
      activation_date: "",
      active: "",
      active_employee_and_subcontractor: "",
      active_time_narrow_comf: "",
      add: "",
      add_button: "",
      add_date: "",
      add_event: "",
      add_media: "",
      add_new: "",
      add_next_route: "",
      add_service: "",
      add_transfer: "",
      added: "",
      adding: "",
      adding_invoice_to_order: "",
      adding_route: "",
      additional_data: "",
      additional_field_on_transport_label: "",
      additional_informations: "",
      additional_load_route: "",
      additional_options: "",
      addressee: "",
      address: "",
      address_details_for_invoices: "",
      address_gcoded_ok: "",
      address_gcoded_wth_nr: "",
      address_info: "",
      address_invoice: "",
      agata_documents_in_order: "",
      agata_number: "",
      agata_order_found: "",
      agata_order_number: "",
      agata_order_processing: "",
      agata_order_update_success: "",
      all: "",
      all_items: "",
      all_records_dropdown: "",
      allow_agd_return: "",
      allow_return: "",
      allowed_order_types: "",
      amazon: "",
      amazon_integration: "",
      amazon_integration_btn: "",
      anomaly_explanation: "",
      apartment_no: "",
      api_disable_failed: "",
      api_disabled: "",
      api_enable_failed: "",
      api_enabled: "",
      api_hooks: "",
      api_nodes_values: "",
      approve: "",
      approve_confirm: "",
      approving_freight_value: "",
      approx_address_lc: "",
      approx_coded_address: "",
      area_code: "",
      article: "",
      article_count: "",
      article_no: "",
      article_table_orderline_delivery: "",
      article_table_orderline_pickup: "",
      arguments: "",
      assembly_service: "",
      assign_freight_cost: "",
      assigned_to_branch: "",
      assigned_to_hub: "",
      attachments: "",
      attribute: "",
      automatic: "",
      auto_planning: "",
      avail_capacity: "",
      available_in_delivery_calendar: "",
      available_terminals_left: "",
      average_sums: "",
      avg_distance_order: "",
      avg_freight_cost_order: "",
      bank_account_number: "",
      bank_deposit_date: "",
      bank_deposition_date: "",
      bar_codes: "",
      bill_instead_of_invoice: "",
      bonus_add_success: "",
      bonus_add_warning: "",
      bonus_calculation: "",
      bonus_delete_confirm: "",
      bonus_delete_success: "",
      bonus_edit: "",
      bonus_not_found: "",
      bonus_save_warning: "",
      bonus_type: "",
      bonus_update_success: "",
      bonuses: "",
      bonuses_sum: "",
      bonuses_sum_global: "",
      bought_by_client: "",
      box_types: "",
      branch: "",
      browser_not_supporting_audio_files: "",
      buy: "",
      calculate_delivery_date_after_pickup: "",
      calculated_logistic_cost: "",
      calculation_date: "",
      calculation_date_from: "",
      calculation_date_to: "",
      call_to_driver: "",
      call_to_driver_confirm: "",
      call_to_driver_info: "",
      call_to_driver_success: "",
      call_to_driver_warning: "",
      can_change_current_status: "",
      can_change_delivery_standard_from_customer_zone: "",
      can_change_service_date_from_customer_zone: "",
      can_reschedule_pickup: "",
      can_schedule_without_scan: "",
      cancel: "",
      cancel_order: "",
      canceled: "",
      cannot_activate_vendor_warning: "",
      cannot_create_return: "",
      cant_create_agd_return: "",
      cant_display_data: "",
      cant_print_labels_for_selected_orders: "",
      cant_return_order_warning: "",
      capacity: "",
      capacity_for_vendor: "",
      capacity_postal_code_restriction: "",
      capacity_saved_success: "",
      capacity_saved_warning: "",
      car: "",
      carrier: "",
      carrier_add_success: "",
      carrier_add_warning: "",
      carrier_delivery: "",
      carrier_list: "",
      carrier_number: "",
      carrier_pickup: "",
      carrier_to_return: "",
      carriers: "",
      carriers_management: "",
      cars: "",
      case_to_return: "",
      cash_on_delivery: "",
      cash_on_delivery_text: "",
      catalog_no: "",
      category_table: "",
      ch_start_lc: "",
      ch_target_lc: "",
      change: "",
      change_amount: "",
      change_date: "",
      change_date_partial: "",
      change_date_popup: "",
      change_not_happening: "",
      change_order_realisation: "",
      change_password_form: "",
      change_pickup_date: "",
      change_rea_date: "",
      change_reason: "",
      change_time: "",
      changes_history: "",
      check: "",
      check_short: "",
      choosing_task_place_onroute: "",
      city: "",
      click_or_drag_docs_for_order: "",
      click_or_drag_file_in_format: "",
      click_or_drag_here: "",
      client_assembly: "",
      client_number: "",
      client_request_change_unavaiable: "",
      client_request_change_used: "",
      client_return: "",
      clients_date: "",
      clone_routes_past_week: "",
      clone_routes_yesterday: "",
      close: "",
      closed: "",
      cod_amount: "",
      cod_and_docs_settlement: "",
      cod_anomaly: "",
      cod_change: "",
      cod_change_reason: "",
      cod_change_remarks: "",
      cod_for_order_added_success: "",
      cod_for_order_delete_success: "",
      cod_for_order_update_success: "",
      cod_info: "",
      cod_info_1: "",
      cod_list: "",
      cod_of_selected_order_has_status_prevents_editing: "",
      cod_of_selected_order_has_status_prevents_settlement: "",
      cod_removal: "",
      cod_removal_confirm: "",
      cod_remove_confirmation: "",
      cod_status: "",
      cod_value_exceed_warning: "",
      collect_electro_scrap: "",
      color: "",
      comment: "",
      common_delivery_pickupdate: "",
      common_no: "",
      common_yes: "",
      communication: "",
      communication_settings: "",
      complaint_consider_time: "",
      complete_marked_fields: "",
      conf_mp_category_id: "",
      conf_mp_cateogry_position: "",
      conf_mp_cateogry_symbol: "",
      conf_mp_config: "",
      conf_mp_optional: "",
      conf_mp_status_id: "",
      conf_mp_status_position: "",
      conf_mp_status_symbol: "",
      conf_password_placeholder: "",
      confirm: "",
      confirm_date_change: "",
      confirmation: "",
      confirmed: "",
      confirmed_short: "",
      connected_orders: "",
      conpeek_integration_disabled: "",
      consider: "",
      contact: "",
      contact_data: "",
      contact_delete_success: "",
      contact_person: "",
      contact_person_info: "",
      contact_phone: "",
      contents: "",
      copy_customer_data: "",
      copy_date_delivery: "",
      costs: "",
      costs_center: "",
      countries: "",
      country: "",
      create_electro_scrap: "",
      create_new_route: "",
      create_return: "",
      create_return_to_order: "",
      create_routes: "",
      created: "",
      created_at: "",
      created_by: "",
      created_date: "",
      created_n_planned: "",
      creation_time: "",
      cross_dock: "",
      csv_b2b_click_or_select_file: "",
      csv_b2b_import_condition1: "",
      csv_b2b_import_condition2: "",
      csv_b2b_import_condition3: "",
      csv_b2b_import_condition4: "",
      csv_b2b_import_condition5: "",
      csv_b2b_import_condition6: "",
      csv_b2b_import_condition7: "",
      csv_b2b_import_condition8: "",
      csv_b2b_import_conditions: "",
      csv_b2b_import_error: "",
      csv_b2b_import_errors: "",
      csv_b2b_import_success: "",
      csv_b2b_importing_file: "",
      csv_b2b_row_error_IsDeliveryStandard: "",
      csv_b2b_row_error_IsEmptyError: "",
      csv_b2b_row_error_IsEmptyOrNumberError: "",
      csv_b2b_row_error_IsNumber: "",
      csv_b2b_row_error_IsValidPostalCode: "",
      csv_b2b_row_error_IsZonePostalCode: "",
      csv_b2b_row_error_MaxLengthError: "",
      csv_b2b_row_error_message: "",
      csv_b2b_unique_parcels_warning: "",
      currency: "",
      currency_symbol: "",
      current_parcels_loc: "",
      custom: "",
      custom_delivery_zone: "",
      customer_address_data: "",
      customer_billing_date: "",
      customer_comment: "",
      customer_contact_data: "",
      customer_date_not_available: "",
      customer_first_and_last_name: "",
      customer_track_url: "",
      customer_zone: "",
      cutoff_list: "",
      damaged_goods: "",
      damaged_packaging: "",
      damaged_parcel: "",
      data_error: "",
      data_import_type: "",
      data_update: "",
      data_validation_messages: "",
      date: "",
      date_and_realisation: "",
      date_change_error: "",
      date_change_error_1: "",
      date_friday: "",
      date_monday: "",
      date_saturday: "",
      date_short_friday: "",
      date_short_monday: "",
      date_short_saturday: "",
      date_short_sunday: "",
      date_short_thursday: "",
      date_short_tuesday: "",
      date_short_wednesday: "",
      date_sunday: "",
      date_thursday: "",
      date_tuesday: "",
      date_warning: "",
      date_wednesday: "",
      days_for_settle: "",
      days_forward: "",
      daysofdelivery_csv_day: "",
      daysofdelivery_csv_filename: "",
      daysofdelivery_csv_reservation: "",
      deactivate: "",
      default: "",
      default_parcel_definition_zpl_file: "",
      default_realization_time: "",
      delete: "",
      delete_record_confirm: "",
      delivered: "",
      deliveries: "",
      delivery: "",
      delivery_address_data: "",
      delivery_area: "",
      delivery_b2b: "",
      delivery_comfort: "",
      delivery_common_delivery_point_list: "",
      delivery_countries: "",
      delivery_date: "",
      delivery_date_calculated_after_pickup_info: "",
      delivery_hours: "",
      delivery_location: "",
      delivery_return: "",
      delivery_schedule: "",
      delivery_service: "",
      delivery_standard: "",
      delivery_standard_add_success: "",
      delivery_standard_caption: "",
      delivery_standard_changes: "",
      delivery_standard_delete_success: "",
      delivery_standard_locks: "",
      delivery_standard_update_success: "",
      delivery_standard_update_warning: "",
      delivery_standards: "",
      delivery_to_point: "",
      delivery_type: "",
      delivery_type_desc: "",
      delivery_vendor_common_point: "",
      delivery_vendor_warehouse_point: "",
      delivery_way_one: "",
      delivery_way_one_greater: "",
      delivery_window: "",
      delivery_zone: "",
      delivery_zones: "",
      deliverydays_available_day: "",
      deliverystandard: "",
      deposit_date: "",
      deposit_history_for_cod: "",
      depositor: "",
      description: "",details: "",
      device_delivery: "",
      device_pickup: "",
      difference: "",
      dim_height: "",
      dim_len: "",
      dim_width: "",
      dim_x_y_z: "",
      dimensions: "",
      direct_delivery: "",
      direct_vendor_settlement_date: "",
      disable_time_window_validation: "",
      display_all: "",
      display_name: "",
      display_to_client_as: "",
      distance: "",
      distance_km: "",
      distance_order_short: "",
      division_hd: "",
      division_info: "",
      divisions: "",
      divisions_list: "",
      doc_pickup: "",
      document: "",
      document_settlement_number: "",
      documents: "",
      documents_settled: "",
      download: "",
      download_excel_spreadsheet: "",
      download_file: "",
      download_sheets_in_zip: "",
      driver: "",
      driver_comment: "",
      driver_license_no: "",
      driver_payment_date: "",
      driver_payment_person: "",
      driver_short: "",
      dts_number: "",
      dts_order_number: "",
      duration: "",
      e_mail: "",
      edit: "",
      edit_employee: "",
      edit_mode: "",
      electro_junk_retrieve_service: "",
      electro_waste_collector: "",
      elevator: "",
      elixir_file_description: "",
      email: "",
      email_already_in_use_warning: "",
      emails_related_to_vendors_cod_settlements: "",
      employee: "",
      employee_code: "",
      employee_phone_number_already_in_use_warning: "",
      employees: "",
      employees_list: "",
      end_customer_address: "",
      enforce_password_change_warning: "",
      enter_address_search: "",
      enter_curr_passwd: "",
      enter_invoice_no: "",
      enter_new_amount: "",
      enter_number_id: "",
      enter_postal_code: "",
      enter_postal_codes_separated: "",
      enter_warehouse: "",
      entered_external_number: "",
      entered_external_number_1: "",
      entering_hub: "",
      epod_disable_customer_comment: "",
      epod_disable_generation: "",
      epod_section: "",
      epod_service_canceled: "",
      epod_service_realized: "",
      epod_service_unrealized: "",
      err_is_route_ended: "",
      err_sm_wrong: "",
      error: "",
      error_occured: "",
      error_order_section_2: "",
      error_section_order: "",
      error_while_sending: "",
      error_while_uploading: "",
      est_delivery_time: "",
      estimated_revenue: "",
      estimated_vendor_cod_settlement_date: "",
      euro_type: "",
      events_history: "",
      every: "",
      expense_of: "",
      explanation: "",
      ext_code: "",
      failed_parcel: "",
      fake_order: "",
      fetch_api_data_warning: "",
      fetch_services_failed: "",
      fetch_vendor_data: "",
      file_size_too_large: "",
      files_limit_reached: "",
      filters: "",
      find: "",
      first_and_last_name: "",
      first_name: "",
      first_parcel_external_number: "",
      fleet: "",
      floor_no: "",
      for_route: "",
      force_add_to_order: "",
      format: "",
      found: "",
      found_parcel: "",
      fr_auto_planning: "",
      freight: "",
      freight_calc: "",
      freight_confirmed_short: "",
      freight_establishment: "",
      freight_history: "",
      freight_order_short: "",
      freight_sum: "",
      freight_sum_global: "",
      freight_withdrawn: "",
      freight_worth: "",
      from: "",
      from_hour: "",
      fuel_type: "",
      function: "",
      gate: "",
      generate_csv: "",
      generate_report: "",
      generate_zip: "",
      geocoding_started_order: "",
      geo_infos: "",
      give_explanation: "",
      go_up: "",
      gps_device_id: "",
      grant_date: "",
      gross_value_info: "",
      group: "",
      has_elevator: "",
      helper: "",
      helpler_short: "",
      hide_scan_history: "",
      hour_interval: "",
      hours: "",
      house_number: "",
      hub: "",
      hub_update_error: "",
      hubs: "",
      html5_not_supported_warning: "",
      ignore_capacity: "",
      ignore_cutoff_mechanism: "",
      image: "",
      import_from_dtssales: "",
      imported_to_wms: "",
      in_track_and_trace: "",
      inactive: "",
      info: "",
      info_change_by_client: "",
      initials: "",
      inprogress_n_done: "",
      invalid_cod_status: "",
      invalid_fields_warning: "",
      invalid_file_type: "",
      invalid_postal_code: "",
      invalid_production_year: "",
      invoice: "",
      invoice_external_number: "",
      invoice_no: "",
      invoice_number: "",
      invoice_settle_time: "",
      is_active: "",
      is_allowed_to_check_vendor_capacity: "",
      is_damaged_goods: "",
      is_damaged_packaging: "",
      is_external_incomplete: "",
      is_inactive: "",
      is_not_supervisor_dropdown: "",
      is_opened: "",
      is_parser_priority_allowed: "",
      is_required: "",
      is_supervisory: "",
      is_supervisor_dropdown: "",
      is_used_by_subcontractor: "",
      is_vat_payer: "",
      item_barcode: "",
      item_name: "",
      keeper: "",
      land_time: "",
      last_7_days: "",
      last_logon: "",
      last_name: "",
      leaving_hub: "",
      library: "",
      list_return_packages: "",
      load_time: "",
      loading: "",
      loading_state: "",
      local_number: "",
      location: "",
      location_deliverydays: "",
      login_failed_empty_login: "",
      login_failed_empty_password: "",
      login_failed_login_attempt_exceeded: "",
      login_failed_login_not_found: "",
      login_failed_password_missmatch: "",
      login_failed_subcontractor_not_active: "",
      login_failed_user_not_active: "",
      login_failed_user_not_found: "",
      login_failed_vendor_not_active: "",
      login_page_enter_email: "",
      login_page_enter_login: "",
      login_page_enter_pwd: "",
      login_page_ip_partial: "",
      login_page_ip_partial_two: "",
      login_page_login: "",
      login_page_pwd_forgot: "",
      login_page_pwd_retrieve: "",
      login_page_reset_pass: "",
      login_page_terms: "",
      logistic_cost: "",
      logistics_centre: "",
      logistics_centre_email: "",
      logistics_centre_info: "",
      logistics_centre_manager: "",
      logout_button: "",
      lost_parcel: "",
      lost_parcels: "",
      main_zone: "",
      manufacturer: "",
      manufacturer_name: "",
      max: "",
      max_dist_from_route: "",
      max_dist_from_staging_point: "",
      max_load: "",
      media: "",
      media_category: "",
      media_list_info: "",
      medias_for_order: "",
      medias_for_order_no: "",
      medias_for_parcel_no: "",
      medias_for_pup_no: "",
      menu_add_bonus: "",
      menu_add_new: "",
      menu_add_new_agata: "",
      menu_autoplanning: "",
      menu_autoplanning_presets: "",
      menu_b2b_orders: "",
      menu_bonuses: "",
      menu_capacity: "",
      menu_capacity_agata: "",
      menu_capacity_holidays: "",
      menu_capacity_hubs: "",
      menu_capacity_postalcode_restrictions: "",
      menu_capacity_postalcodes: "",
      menu_capacity_vendorgroup: "",
      menu_capacity_vendorgroup_hubs: "",
      menu_capacity_zones: "",
      menu_car_list: "",
      menu_carriers: "",
      menu_cars: "",
      menu_centiro: "",
      menu_centiro_status: "",
      menu_cod: "",
      menu_cod_edit: "",
      menu_cod_list: "",
      menu_cod_list_routes: "",
      menu_cod_routes_files: "",
      menu_cod_routes_files_mt940: "",
      menu_cod_routes_files_sepa: "",
      menu_cod_settlement: "",
      menu_cod_value_change: "",
      menu_complaint_create: "",
      menu_complaint_pendinglist: "",
      menu_complaint_search: "",
      menu_complaints: "",
      menu_csv: "",
      menu_csv_orders: "",
      menu_customers_invoices: "",
      menu_datetimechange: "",
      menu_deliverydays: "",
      menu_deliverydays_change_view: "",
      menu_deliverydays_extended: "",
      menu_deliverydays_standalone: "",
      menu_divisions: "",
      menu_divisions_list: "",
      menu_docs: "",
      menu_efficiency_report: "",
      menu_employees: "",
      menu_employees_list: "",
      menu_firstdata: "",
      menu_generate_test_route: "",
      menu_hubs: "",
      menu_hubs_list: "",
      menu_hubs_map: "",
      menu_integrations: "",
      menu_invoices: "",
      menu_list: "",
      menu_list_vendor: "",
      menu_manufactuter_pickup: "",
      menu_marketplace: "",
      menu_messenger_config: "",
      menu_mt940: "",
      menu_order_info: "",
      menu_orders: "",
      menu_orders_list: "",
      menu_phone_survey: "",
      menu_phone_survey_review: "",
      menu_planning: "",
      menu_postal_codes: "",
      menu_postal_codes_whitelist: "",
      menu_presentations: "",
      menu_printers: "",
      menu_producers: "",
      menu_quality: "",
      menu_reports: "",
      menu_route_change_status: "",
      menu_route_photos: "",
      menu_route_settlement: "",
      menu_routes2: "",
      menu_routes_invoices: "",
      menu_routes_list: "",
      menu_service_type: "",
      menu_services_list: "",
      menu_settings: "",
      menu_shipping: "",
      menu_shipping_doc: "",
      menu_shipping_docs: "",
      menu_statuses: "",
      menu_subcontractors: "",
      menu_subcontractors_list: "",
      menu_subcontractors_pusher: "",
      menu_subcontractors_routes: "",
      menu_tasks_list: "",
      menu_translates_list: "",
      menu_unprocessed_scans: "",
      menu_user_add: "",
      menu_users_list: "",
      menu_variance_report: "",
      menu_vendor_addressbook: "",
      menu_vendor_config: "",
      menu_vendors: "",
      menu_vendors_list: "",
      menu_warehouses: "",
      menu_warehouses_list: "",
      menu_worldline: "",
      menu_zones_testing: "",
      message: "",
      metadata_type: "",
      metadata_type_delete_success: "",
      metadata_types: "",
      min: "",
      min_dist_from_cl: "",
      missing_parcel: "",
      missing_reason_or_explanation: "",
      missing_required_config_for_roles: "",
      missing_required_contacts_warning: "",
      mobile_phone: "",
      model: "",
      month_april: "",
      month_august: "",
      month_december: "",
      month_february: "",
      month_january: "",
      month_july: "",
      month_june: "",
      month_march: "",
      month_may: "",
      month_november: "",
      month_october: "",
      month_september: "",
      monthly_data: "",
      more: "",
      mphone_contact: "",
      mshp_delivery_days: "",
      multiple_deadline_changes: "",
      mv_checked: "",
      name: "",
      native_language: "",
      new_date_time: "",
      new_order: "",
      new_order_info_1: "",
      new_order_info_2: "",
      new_order_info_3: "",
      new_order_info_4: "",
      new_password_placeholder: "",
      new_pickup_date: "",
      new_reason: "",
      new_status: "",
      new_values: "",
      new_vendor: "",
      no: "",
      no_access: "",
      no_available_pickup_dates_found: "",
      no_available_worldline_terminals: "",
      no_capacity_available: "",
      no_cod: "",
      no_cod_for_selected_order: "",
      no_comments: "",
      no_data_available: "",
      no_date: "",
      no_date_on_route: "",
      no_default_vendor_config_for_zadbanopup: "",
      no_delivery_country_selected: "",
      no_employee_assigned: "",
      no_entity_found: "",
      no_geolocation: "",
      no_hubs_found: "",
      no_information: "",
      no_order_with_given_number: "",
      no_orders_to_print_selected: "",
      no_pack_enroute: "",
      no_permissions_warning: "",
      no_pickup_date_selected: "",
      no_postal_code_selected: "",
      no_records: "",
      no_results: "",
      no_scans_avail: "",
      no_search_answers: "",
      no_service_date_selected: "",
      no_status_selected: "",
      no_warehouse_selected: "",
      none: "",
      nothing: "",
      number_ext_operator: "",
      number_external: "",
      old_reason: "",
      old_values: "",
      on_customer_decision: "",
      on_day: "",
      on_dts_decision: "",
      on_vendor_decision: "",
      one_day_route: "",
      one_man_route: "",
      only_active: "",
      only_branded_delivery_orders_warning: "",
      onroute_ordersearch_tool: "",
      opening_hours: "",
      operation_action: "",
      operator: "",
      operator_status_history: "",
      operator_symbol: "",
      or: "",
      order: "",
      order_articles: "",
      order_canceled_info: "",
      order_create_info: "",
      order_creation_date: "",
      order_executed: "",
      order_executed_message: "",
      order_ext_number: "",
      order_geocoding_failed: "",
      order_geocoding_started: "",
      order_gross_value: "",
      order_id: "",
      order_is_not_cod: "",
      order_link_copied: "",
      order_lowkey: "",
      order_metadata_missing_values: "",
      order_not_found: "",
      order_number: "",
      order_number_dts: "",
      order_number_found: "",
      order_number_not_found: "",
      order_number_not_found_or_invalid: "",
      order_number_vendor: "",
      order_numbers: "",
      order_parcel_nr: "",
      order_planned: "",
      order_planned_message: "",
      order_realization_abroad_poland: "",
      order_realisation_date: "",
      order_registration_date: "",
      order_service: "",
      order_type: "",
      order_wrong_status: "",
      orders_in_proximity: "",
      orders_range: "",
      other: "",
      other_infos: "",
      p_width: "",
      pack_list_enroute: "",
      package_count_vendor: "",
      package_number: "",
      packages_count: "",
      packaging_type: "",
      paid_amount: "",
      param_name: "",
      param_value: "",
      parameters_exceed_maximum_values: "",
      params: "",
      parcel: "",
      parcel_counter: "",
      parcel_damage_protocols: "",
      parcel_data_change: "",
      parcel_depth_exceeds_limit: "",
      parcel_ext_number: "",
      parcel_height_exceeds_limit: "",
      parcel_id: "",
      parcel_needs_attention: "",
      parcel_number: "",
      parcel_state: "",
      parcel_width_exceeds_limit: "",
      parcels: "",
      parcels_already_exists: "",
      parcels_light_32kg: "",
      passing: "",
      passwd_change_error_msg: "",
      passwd_change_error_wrong_pswd: "",
      passwd_change_finished: "",
      passwd_changed_redirect_msg: "",
      passwd_must_contain_error: "",
      passwd_must_contain_error_char: "",
      passwd_placeholder: "",
      password: "",
      password_change_instruction_info: "",
      past_failure: "",
      past_failure_count: "",
      path: "",
      payer: "",
      payment_account_number: "",
      payment_block_reason_no_payment: "",
      payment_block_reason_unspecified: "",
      payment_card_number: "",
      payment_channel: "",
      payment_for_additional_services: "",
      payments: "",
      pays_vat_no: "",
      pays_vat_yes: "",
      pcs: "",
      person: "",
      person_confirmed_freight_cost: "",
      person_confirmed_route: "",
      person_currently_responsible_for_cod: "",
      person_making_deposit: "",
      person_name: "",
      person_surname: "",
      phone_number_info: "",
      phone_number_without_prefix: "",
      phone_prefix: "",
      pick_prefix: "",
      picking_order: "",
      pickup: "",
      pickup_zone: "",
      pickup_by_vendor: "",
      pickup_data: "",
      pickup_date: "",
      pickup_date_too_early: "",
      pickup_date_too_early_same_day: "",
      pickup_date_too_late: "",
      pickup_date_warnings: "",
      pickup_point_location: "",
      pickup_point_warehouse_details: "",
      pickup_return: "",
      pickup_type: "",
      pickup_vendor_data: "",
      pin_code: "",
      pin_code_required_warning: "",
      pin_text_variable_1: "",
      pin_text_variable_2: "",
      pin_text_variable_3: "",
      pin_text_variable_4: "",
      pin_text_variable_5: "",
      pin_text_variable_6: "",
      pin_text_variable_7: "",
      pin_text_variable_8: "",
      planned_delivery: "",
      planned_pickup: "",
      planner_change_order_date_impossible: "",
      planning_added_linked_task: "",
      planning_auto_add_bundled_task: "",
      planning_coords_acc_user: "",
      planning_double_task_error: "",
      planning_gcode_again: "",
      planning_geocoding_error: "",
      planning_loading: "",
      planning_no_cords_error: "",
      planning_no_linked_task_error: "",
      planning_not_ready_f_planning: "",
      planning_order_not_added_error: "",
      planning_pok: "",
      planning_route_add_error: "",
      planning_route_approval: "",
      planning_route_approval_1: "",
      planning_route_confirm_no_car_error: "",
      planning_route_confirm_no_driver_error: "",
      planning_route_el_error: "",
      planning_route_question: "",
      planning_save_route_error: "",
      planning_tasks: "",
      planning_unloading: "",
      plate_number: "",
      please_correctly_complete_fields: "",
      please_select_delivery_standard: "",
      please_select_order_type: "",
      please_select_service_type: "",
      postal_code: "",
      postal_code_restriction_add_success: "",
      postal_code_restriction_add_warning: "",
      postal_code_restriction_delete_success: "",
      postal_code_restriction_delete_warning: "",
      postal_code_restriction_exists_warning: "",
      postal_code_restriction_info_text: "",
      postal_code_restriction_update_success: "",
      postal_code_restriction_update_warning: "",
      postal_code_restrictions_info: "",
      postal_code_update_success: "",
      postal_code_update_warning: "",
      postal_codes: "",
      preprocesses: "",
      previous_reason: "",
      price: "",
      print_qr_code: "",
      printer: "",
      printer_delete_confirm: "",
      printer_delete_success: "",
      printer_delete_warning: "",
      printer_list: "",
      printer_save_success: "",
      printer_save_warning: "",
      printing: "",
      priority: "",
      priority_raise_warning_window: "",
      product_number: "",
      provide_return_create_reason: "",
      pup_open_hours: "",
      pusher_address: "",
      pusher_api_executions: "",
      pusher_arrival: "",
      pusher_arrival_date: "",
      pusher_comments: "",
      pusher_communication_history: "",
      pusher_customer: "",
      pusher_customer_data_changes: "",
      pusher_customer_site: "",
      pusher_delivery_date_changes: "",
      pusher_delivery_standard: "",
      pusher_division: "",
      pusher_extend_priority: "",
      pusher_found_routes_count: "",
      pusher_hub_end: "",
      pusher_hub_start: "",
      pusher_max_routes_count: "",
      pusher_order_number: "",
      pusher_order_number_ext: "",
      pusher_orderl_parcels: "",
      pusher_orders_number: "",
      pusher_parcel_label: "",
      pusher_parcel_labels: "",
      pusher_parcel_number: "",
      pusher_parcels: "",
      pusher_parcels_deleted: "",
      pusher_parcels_scanned: "",
      pusher_phone: "",
      pusher_pickup_date: "",
      pusher_pickup_date_changes: "",
      pusher_prints: "",
      pusher_realis: "",
      pusher_realisation_date: "",
      pusher_realisation_date_info: "",
      pusher_regeocode: "",
      pusher_route_count: "",
      pusher_route_type: "",
      pusher_scans: "",
      pusher_search_by: "",
      pusher_search_placeholder: "",
      pusher_search_placeholder_beta: "",
      pusher_services: "",
      pusher_services_order: "",
      pusher_source: "",
      pusher_status: "",
      pusher_table_complaints: "",
      pusher_table_copy_link: "",
      pusher_table_country: "",
      pusher_table_created_order: "",
      pusher_table_createtime: "",
      pusher_table_customer: "",
      pusher_table_delivery: "",
      pusher_table_deliverydate: "",
      pusher_table_division: "",
      pusher_table_executed_order: "",
      pusher_table_fake_order: "",
      pusher_table_ikea_order: "",
      pusher_table_in: "",
      pusher_table_lastroute: "",
      pusher_table_no: "",
      pusher_table_number: "",
      pusher_table_number_ext: "",
      pusher_table_order_change_date: "",
      pusher_table_order_edit: "",
      pusher_table_order_has_services: "",
      pusher_table_out: "",
      pusher_table_paused_order: "",
      pusher_table_priority_order: "",
      pusher_table_progress_order: "",
      pusher_table_receiver: "",
      pusher_table_rejected_order: "",
      pusher_table_route_settled: "",
      pusher_table_routes2_start_time_short: "",
      pusher_table_routes2_stop_time_short: "",
      pusher_table_scans: "",
      pusher_table_sender: "",
      pusher_table_source: "",
      pusher_table_status: "",
      pusher_table_street: "",
      pusher_table_survey_details: "",
      pusher_table_survey_generate: "",
      pusher_table_take: "",
      pusher_table_type: "",
      pusher_table_value: "",
      pusher_table_zone: "",
      pusher_tasks: "",
      pusher_tasks_edit: "",
      pusher_type: "",
      pusher_waybill: "",
      qualifications: "",
      quality_url: "",
      quantity: "",
      query_code: "",
      realisation_date_error: "",
      reason: "",
      reason_change_success: "",
      reason_change_warning: "",
      reason_history: "",
      receiver: "",
      recipient: "",
      recipient_logo: "",
      record_already_exists: "",
      recovery_mode: "",
      redirect_to_user_list_info: "",
      refresh: "",
      refresh_data: "",
      region: "",
      rejected_transactions: "",
      related_employee: "",
      related_vendor: "",
      relation: "",
      release_on_route: "",
      remove: "",
      remove_amount: "",
      remove_service_confirm: "",
      remove_service_exclusion_confirm: "",
      report_created: "",
      report_not_created: "",
      report_of_incompability: "",
      required: "",
      reservation_count_deliverydays: "",
      results_counter: "",
      ret_res_limit: "",
      return: "",
      return_already_exists: "",
      return_created: "",
      return_created_success: "",
      return_not_allowed: "",
      return_warehouse: "",
      returns: "",
      roles: "",
      roles_or_permissions: "",
      route: "",
      route_adding_while_locked: "",
      route_adding_while_locked_partial: "",
      route_been_conf_partial: "",
      route_capital: "",
      route_del_acc: "",
      route_desc: "",
      route_has_invoice_issued_warning: "",
      route_not_confirmed: "",
      route_number: "",
      route_of_vendor: "",
      route_type: "",
      routes: "",
      routes_counter: "",
      salary: "",
      sales_keeper: "",
      sample_file: "",
      sample_file_or_photo_desc: "",
      save: "",
      save_changes: "",
      save_order: "",
      save_route: "",
      saved: "",
      saving_freight_value: "",
      scan_from_info: "",
      scan_history: "",
      scan_list: "",
      scan_location: "",
      scan_time: "",
      scanned: "",
      scanned_by: "",
      scanned_on: "",
      scans_media_list: "",
      scheduling_delay: "",
      search_assortment: "",
      search_button: "",
      search_car: "",
      search_employee: "",
      search_order: "",
      search_placeholder: "",
      search_route: "",
      section_customer: "",
      section_delivery: "",
      section_details: "",
      select: "",
      select_bank_transfer_date: "",
      select_cod_billing_date: "",
      select_country: "",
      select_date: "",
      select_delivery_zone: "",
      select_dropdown: "",
      select_event_hub: "",
      select_event_zone: "",
      select_hd_division: "",
      select_low_char: "",
      select_month: "",
      select_order_type: "",
      select_pickup_date: "",
      select_pp_warehouse_location: "",
      select_statuses: "",
      select_task_type: "",
      selected: "",
      selected_roles: "",
      send: "",
      send_report_of_incompability: "",
      send_route_data_agata_ecom: "",
      send_route_data_agito: "",
      send_route_pdf_agata_ecom: "",
      send_route_pdf_agito: "",
      send_sheets_to_vendors: "",
      send_sms: "",
      send_sms_characters_count: "",
      send_sms_content: "",
      send_waybill: "",
      send_zpl: "",
      sender: "",
      sending_test: "",
      sepa_file_description: "",
      separate_orders_info: "",
      service: "",
      service_agd: "",
      service_agd_built_in: "",
      service_categories: "",
      service_configuration: "",
      service_count: "",
      service_created: "",
      service_delete_success: "",
      service_device_return_not_found: "",
      service_electro_waste: "",
      service_electro_waste_paid: "",
      service_electroscrap_created: "",
      service_furniture: "",
      service_gas: "",
      service_gas_power: "",
      service_hd: "",
      service_kitchen: "",
      service_locks: "",
      service_no: "",
      service_not_created: "",
      service_not_found: "",
      service_power: "",
      service_realization_area: "",
      service_rtv: "",
      service_service_team: "",
      service_serviceman: "",
      service_sport: "",
      service_status_update_success: "",
      service_status_update_warning: "",
      service_type: "",
      service_type_delete_success: "",
      service_type_header: "",
      service_types_for_delivery_standard: "",
      service_update_success: "",
      service_update_warning: "",
      service_value: "",
      service_water: "",
      services: "",
      services_number_ext: "",
      services_time_window: "",
      services_to_add: "",
      services_vpusher: "",
      set: "",
      set_customer_service_date_on_registration: "",
      settlement_person: "",
      short_name: "",
      show_all_operation_resources: "",
      show_cutoff_times: "",
      show_delivery_days: "",
      show_fake_tasks: "",
      show_form: "",
      show_open_hours: "",
      show_parcel_labels: "",
      show_scan_history: "",
      show_transfers_to_distribution_hub: "",
      show_waybill: "",
      single_waybill: "",
      single_waybill_without_epod: "",
      skip_setting_delivery_date_on_update: "",
      skip_setting_pickup_date_on_update: "",
      sort_ascending: "",
      sort_descending: "",
      source: "",
      source_dtssales: "",
      source_lowkey: "",
      source_planner: "",
      sscc_cdu: "",
      staging_area: "",
      stationary_phone: "",
      status: "",
      status_code: "",
      status_confirmation: "",
      status_date: "",
      status_history: "",
      status_lowkey: "",
      status_need_confirm: "",
      status_route_search_count: "",
      status_send_success: "",
      stock: "",
      street: "",
      street_no: "",
      street_number_and_apartment_no: "",
      subcontr: "",
      subcontractor: "",
      subcontractor_details: "",
      subcontractor_list: "",
      subcontractor_update_success: "",
      subcontractor_update_warning: "",
      subcontractors_exists_warning_info: "",
      success: "",
      sum: "",
      summary: "",
      summation: "",
      sums: "",
      supervising_hub: "",
      symbol: "",
      symbol_already_exists: "",
      symbol_external: "",
      t_and_t_locks: "",
      task_order_optimal: "",
      task_route: "",
      task_type: "",
      tasks_edit_success: "",
      tasks_lowkey: "",
      tax_id: "",
      tax_id_number: "",
      team_name: "",
      telephone_short: "",
      temporary_employee: "",
      test_order: "",
      testing_delivery_zones: "",
      then_used_as_login: "",
      time: "",
      time_from: "",
      time_minutes: "",
      time_to: "",
      title: "",
      tms_front_page_opts: "",
      tms_translated: "",
      to: "",
      to_hour: "",
      to_many_zadbano_changes: "",
      today: "",
      tomorrow: "",
      total_time: "",
      total_volume: "",
      total_waybill: "",
      total_weight: "",
      transaction_name: "",
      transfer: "",
      transfer_made: "",
      transfer_prepared: "",
      transfer_time: "",
      transfer_to_distribution_hub: "",
      transfer_to_distribution_hub_short: "",
      transit_gateway_hub: "",
      translate_update_success: "",
      translates_desc_cz: "",
      translates_desc_de: "",
      translates_desc_en: "",
      translates_desc_pl: "",
      translates_desc_ro: "",
      translates_desc_sk: "",
      translates_header: "",
      translates_type: "",
      travel_time: "",
      two_man_route: "",
      type: "",
      type_of_service_provided: "",
      unavailable_in_t_and_t: "",
      undefined_code: "",
      unknown_error_occured: "",
      updated_by: "",
      updated_date: "",
      use_automatic_invoice_generation: "",
      used_code: "",
      user: "",
      user_create_confirm: "",
      user_delete_confirm: "",
      user_delete_success: "",
      user_delete_warning: "",
      user_disable_for_DTS_Trasa: "",
      user_disable_for_DTS_Trasa_warn: "",
      user_edit: "",
      user_enable_for_DTS_Trasa: "",
      user_must_change_password_next_login: "",
      user_name_already_exists: "",
      user_phone_number_already_in_use_warning: "",
      user_save_warning: "",
      user_update_confirm: "",
      user_wrong_email_domain_warning: "",
      users: "",
      users_list: "",
      ut_number: "",
      value: "",
      value_must_be_unique: "",
      values: "",
      vat_code: "",
      vehicle_already_exists: "",
      vehicle_edit: "",
      vehicle_list: "",
      vehicle_type: "",
      vehicle_year: "",
      vendor: "",
      vendor_addressbook: "",
      vendor_addressbook_address: "",
      vendor_addressbook_apartmentNo: "",
      vendor_addressbook_city: "",
      vendor_addressbook_contactPhone: "",
      vendor_addressbook_country: "",
      vendor_addressbook_deleted: "",
      vendor_addressbook_floorNo: "",
      vendor_addressbook_name: "",
      vendor_addressbook_postalCode: "",
      vendor_addressbook_remarks: "",
      vendor_addressbook_street: "",
      vendor_addressbook_streetNo: "",
      vendor_addressbook_updated: "",
      vendor_addressbook_vendor: "",
      vendor_bank_account_added: "",
      vendor_bank_account_bic_no: "",
      vendor_bank_account_country: "",
      vendor_bank_account_currency: "",
      vendor_bank_account_delete_error: "",
      vendor_bank_account_deleted: "",
      vendor_bank_account_iban_no: "",
      vendor_bank_account_load_list_error: "",
      vendor_bank_account_name: "",
      vendor_bank_account_nrd_no: "",
      vendor_bank_account_plus_days: "",
      vendor_bank_account_updated: "",
      vendor_cod_settlement: "",
      vendor_contact_add_success: "",
      vendor_contact_update_success: "",
      vendor_countries_add_error: "",
      vendor_countries_country: "",
      vendor_countries_default: "",
      vendor_countries_delete_error: "",
      vendor_countries_deleted: "",
      vendor_countries_description: "",
      vendor_countries_load_list_error: "",
      vendor_countries_set_default_error: "",
      vendor_countries_updated: "",
      vendor_data: "",
      vendor_delivery_days: "",
      vendor_depositor: "",
      vendor_depositor_stock: "",
      vendor_depositor_vendor_stock: "",
      vendor_email_domain: "",
      vendor_groups: "",
      vendor_info: "",
      vendor_metadata_type_add_success: "",
      vendor_metadata_type_update_success: "",
      vendor_same_as_pickup: "",
      vendor_service_date_after_pickup_info: "",
      vendor_service_no: "",
      vendor_settings: "",
      vendor_settlement_date: "",
      vendor_store_group: "",
      vendor_store_group_delete_success: "",
      vendor_store_group_load_list_error: "",
      vendor_store_group_update_success: "",
      vendor_store_group_update_warning: "",
      vendor_symbol: "",
      vendor_symbol_second: "",
      vendor_users_load_list_error: "",
      vendor_value_occupied: "",
      vendor_warehouse_add_success: "",
      vendor_warehouse_point: "",
      vendor_warehouse_update_success: "",
      vendor_wh_hkey: "",
      vendor_wh_lowkey: "",
      vendor_will_be_added: "",
      vendor_will_be_deactivated: "",
      vendors_date: "",
      vendors_dwh: "",
      vendors_in_group_count: "",
      vendors_list_in_group: "",
      vendors_wh: "",
      volume: "",
      volume_short: "",
      vpusher_actions: "",
      vpusher_edit_prints: "",
      want_continue_confirm: "",
      warehouse_address: "",
      warehouse_confirm_success: "",
      warehouse_confirm_warning: "",
      warehouse_delete_success: "",
      warehouse_location_pin_update_success: "",
      warehouse_location_pin_update_warning: "",
      warehouse_not_found: "",
      warehouse_open_hours: "",
      warehouse_pickup: "",
      warehouses: "",
      warehousing: "",
      warehousing_depositor_color: "",
      warning: "",
      waybills_by_one: "",
      waybills_by_one_without_epod: "",
      webform_carrier: "",
      webform_carrier_number: "",
      webform_case: "",
      webform_case_number: "",
      weight: "",
      wh_zone: "",
      who_scanned: "",
      who_set: "",
      with_assembly_bracket: "",
      with_crossdock_dropdown: "",
      with_delivery_zone_dropdown: "",
      with_fleet_dropdown: "",
      with_photo: "",
      with_warehouse_dropdown: "",
      withdraw_approval: "",
      withdrawal: "",
      withdrawal_confirmation: "",
      withdrawal_freight_acceptance: "",
      withdrawing_freight: "",
      without_crossdock_dropdown: "",
      without_delivery_zone_dropdown: "",
      without_fleet_dropdown: "",
      without_invoices: "",
      without_photo: "",
      without_warehouse_dropdown: "",
      work_end_date: "",
      work_start_date: "",
      work_time: "",
      wrong_directions_for_route: "",
      wrong_task_type_for_route: "",
      yes: "",
      yesterday: "",
      you_account_is_blocked_for_reason: "",
      your_routes: "",
      zone: "",
      zone_capacity_country_display_mode: "",
      zone_capacity_country_edit_mode: "",
      zone_capacity_zone_display_mode: "",
      zone_capacity_zone_edit_mode: "",
      zones: ""
    }
  }
});
module.exports = {
  data() {
    return { languageSuffix: "PL" };
  },
  ready() {
    if (this.$el && this.$el.className && typeof this.$el.className === "string" && this.$el.className.includes("sidebar")) {
      this.getTranslatesGlobal();
    }
  },
  computed: {
    $languageSuffixGlobal: {
      get() {
        return globalData.$data.$languageSuffixGlobal;
      },
      set(newSuffix) {
        globalData.$data.$languageSuffixGlobal = newSuffix;
      }
    },
    $translatesGlobal: {
      get() {
        return globalData.$data.$translates;
      },
      set(tr) {
        globalData.$data.$translates = tr;
      }
    }
  },
  methods: {
    translateSymbol(symbol) {
      return this.$translatesGlobal[symbol] || this.symbol;
    },
    getTranslatesGlobal() {
      const languageSuffixLocal = localStorage.getItem("languageSuffix");
      this.$languageSuffixGlobal = languageSuffixLocal || this.$languageSuffixGlobal;
      const lastTranslatesRefresh = localStorage.getItem(`lastTranslatesRefresh_${this.$languageSuffixGlobal}`);
      const translatesLocal = JSON.parse(localStorage.getItem(`translates_${this.$languageSuffixGlobal}`) || null);
      let needRefresh = !lastTranslatesRefresh || !translatesLocal || moment().diff(moment(lastTranslatesRefresh), "hours") > 24;
      if (languageSuffixLocal && translatesLocal && !needRefresh) {
        this.$languageSuffixGlobal = languageSuffixLocal;
        for (const [key] of Object.entries(this.$translatesGlobal)) {
          if (!translatesLocal[key]) {
            console.log(`Missing translate for key: [${key}]`);
            needRefresh = true;
          }
          this.$translatesGlobal[key] = translatesLocal[key];
        }
      }
      const params = { languageSuffix: this.$languageSuffixGlobal };
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const dev = urlParams.get("dev");
      if (dev) {
        needRefresh = true;
      }
      if (needRefresh) {
        localStorage.setItem("languageSuffix", this.languageSuffix);
        if (dev) {
          params.dev = dev;
        }
        this.request({ endPoint: ENDPOINTS.translates.list }, params).done(data => {
          localStorage.setItem(`translates_${this.$languageSuffixGlobal}`, JSON.stringify(data));
          localStorage.setItem(`lastTranslatesRefresh_${this.$languageSuffixGlobal}`, moment().format("YYYY-MM-DD HH:mm:ss"));
          for (const [key] of Object.entries(data)) {
            this.$translatesGlobal[key] = data[key];
          }
        });
      }
    }
  },
  mixins: [require("./http")]
};
