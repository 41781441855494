$(() => {
    const validator = window['vue-validator'];
    if (validator) {
        Vue.use(validator);
    }
    const strategies = Vue.config.optionMergeStrategies;
    strategies.events = strategies.methods;
    Vue.config.debug = true;
    Vue.mixin(require("../../scripts/mixins/translateData"));
    indexComponent = new Vue(require("../../components/index.vue"));
});
