/**
 * Created by dzwigalowski on 2015-11-20.
 */
module.exports = {
    methods: {
        init: function() {
            this._private = {
                map: null,
                markers: {
                    hub: {},
                    hubs: []
                },
                polygons: [],
                infoWindow: null,
                mapStyles: {
                    light: [{
                        "featureType": "landscape",
                        "stylers": [{"hue": "#F1FF00"}, {"saturation": -27.4}, {"lightness": 9.4}, {"gamma": 1}]
                    }, {
                        "featureType": "road.highway",
                        "stylers": [{"hue": "#0099FF"}, {"saturation": -20}, {"lightness": 36.4}, {"gamma": 1}]
                    }, {
                        "featureType": "road.arterial",
                        "stylers": [{"hue": "#00FF4F"}, {"saturation": 0}, {"lightness": 0}, {"gamma": 1}]
                    }, {
                        "featureType": "road.local",
                        "stylers": [{"hue": "#FFB300"}, {"saturation": -38}, {"lightness": 11.2}, {"gamma": 1}]
                    }, {
                        "featureType": "water",
                        "stylers": [{"hue": "#0096DF"}, {"saturation": 4.2}, {"lightness": -63.4}, {"gamma": 1}]
                    }, {
                        "featureType": "poi",
                        "stylers": [{"hue": "#9FFF00"}, {"saturation": 0}, {"lightness": 0}, {"gamma": 1}]
                    }]
                }
            };
            var options = {
                zoom: 6,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                zoomControl: true,
                streetViewControl: true,
                zoomControlOptions: {
                    style: google.maps.ZoomControlStyle.SMALL
                },
                styles: this._private.mapStyles.light
            };
            google.maps.visualRefresh = true;
            this._private.map = new google.maps.Map(this.$el, options);
            this._private.map.setCenter(new google.maps.LatLng(52.229902, 19.001233));
            this._private.infoWindow = new google.maps.InfoWindow();


        }
    }
}
