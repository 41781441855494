module.exports = {
  data() {
    return {
      userData: {}
    };
  },
  methods: {
    checkUserRoles(roles) {
      const userRole = this.userData.Roles;
      if (!_.isArray(roles)) {
        roles = [roles];
      }
      roles.push("SUPER_ADMIN");
      let check;
      _.forEach(roles, role => {
        check = _.some(userRole, { Symbol: role });
        if (check) {
          return false;
        }
      });
      return check;
    },
    getUserData() {
      if (this.userData && this.userData.ID) {
        return;
      }
      return this
        .request({ endPoint: `${ENDPOINTS.auth.userinfo}?languageSuffix=${this.$languageSuffixGlobal}` })
        .then(data => (this.userData = data));
    }
  },
  created() {
    $.when(this.getUserData()).then(() => $(window).trigger("user-info-ready"));
  }
};
