require("insert-css")(".item{padding-top:5px!important;padding-bottom:5px!important}.dropdown .menu{min-width:0}.filters .item label{display:inline-block;min-width:25%}.stackable.fixed.menu>*{padding-top:10px!important;padding-bottom:10px!important}.filtered{background-color:#fbbd08!important}@media screen and (max-width:768px){.menu .item:empty{display:none!important}}.menu{padding-left:0!important;padding-right:0!important}");
var __vue_template__ = "<div class=\"ui top borderless small ^stackable^ fixed menu\">\n    <a class=\"ui item\" id=\"trigger-sidebar\" v-if=\"!isPaymentBlockReason\">\n      <i class=\"sidebar icon\"></i>&nbsp;Menu\n    </a>\n    <div v-class=\"filtered: filtersApplied\" v-show=\"showFilterIcon\" class=\"ui left dropdown item\">\n      <i class=\"icons\">\n        <i class=\"large filter icon\"></i>\n        <i v-show=\"filtersApplied\" class=\"warning circle corner icon\"></i>\n      </i>&nbsp;\n      <div class=\"text\"><content select=\".filters-label\"></content></div>\n      <i class=\"dropdown icon\" id=\"#arrowDown\"></i>\n      <div class=\"menu\"><content select=\".filters\"></content></div>\n    </div>\n    <div class=\"left item\"><content select=\".left.item\"></content></div>\n    <div class=\"item\"><content select=\".item:not(.left)\"></content></div>\n    <div class=\"right item\"><content select=\".search\"></content></div>\n    <div class=\"right item\">\n      <dts-dropdown v-if=\"$languageSuffixGlobal\" values=\"{{@ languageSuffix }}\" text-value=\"true\" options=\"{{ languages }}\" image-mode=\"true\" without-empty=\"true\"></dts-dropdown>\n      <user-info user=\"{{@ usr }}\" user-info=\"{{@ userInfo }}\"></user-info>\n    </div>\n    <session-expire-timer>\n  </session-expire-timer></div>";
module.exports = {
  //el: '#app',
  data() {
    return {
      filtersApplied: false,
      filtersDropdownsNo: 0,
      filtersAppliedNo: 0,
      filters: [],
      showFilterIcon: true,
      usr: "",
      userInfo: null,
      languages: [
        { value: "PL", text: "/css/images/pl.png" },
        { value: "EN", text: "/css/images/en.png" },
        { value: "SK", text: "/css/images/sk.png" },
        { value: "CZ", text: "/css/images/cz.png" }
      ]
    };
  },
  props: ["showFilterIcon", "userInfo"],
  computed: {
    isPaymentBlockReason() {
      return !!_.get(this, "userInfo.PaymentBlockReason", null);
    }
  },
  ready() {
    this.languageSuffix = this.$languageSuffixGlobal || "PL";
    this.$set("usr", this.userInfo);
    $(".dropdown.item").dropdown();
    if (!this.isPaymentBlockReason) {
      $("#trigger-sidebar").click(() => $(".ui.sidebar").sidebar("toggle"));
    }
    _.forEach(this.$children, child => {
      if (child.$el.className === "ui dropdown") {
        this.filtersDropdownsNo++;
      }
    });
    if (this.filtersDropdownsNo === 0) {
      $(".ui.left.dropdown.item").hide();
    }
  },
  watch: {
    usr(nv, ov) {
      this.userInfo = nv;
    },
    languageSuffix(nv, ov) {
      this.$languageSuffixGlobal = nv;
      localStorage.setItem("languageSuffix", nv);
      this.getTranslatesGlobal();
    }
  },
  events: {
    filtered(obj) {
      const idx = _.indexOf(this.$children, obj.item);
      switch (obj.val) {
        case "-":
          this.filters[idx] = 0;
          break;

        default:
          this.filters[idx] = 1;
          break;
      }
      if (_.indexOf(this.filters, 1) !== -1) {
        this.filtersApplied = true;
      } else {
        this.filtersApplied = false;
      }
    }
  },
  components: {
    "user-info": require("./user-info.vue"),
    dtsDropdown: require("./dtsDropdown.vue"),
    "session-expire-timer": require("./sessionExpireTimer.vue")
  }
};
;(typeof module.exports === "function"? module.exports.options: module.exports).template = __vue_template__;
